import React from "react";
import CookieBanner from "../../components/cookie-banner";

function TestCookie() {
    return(
        <CookieBanner/>
    )
}

export default TestCookie;
